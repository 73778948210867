import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { ProtectedRoute } from './protectedRoute';
import { PublicRoute } from './publicRoute';
import AuthProvider from './AuthProvider';
import LayoutComponent from '../components/LayoutComponent';
import PriceCalculationPage from '../pages/pricecalculation';
import RequestSuccess from '../pages/pricecalculation/success';
import TermsConditionPage from '../pages/terms-condition';
import PrivacyPolicyPage from '../pages/privacy-policy';
import CustomerAcceptRequest from '../pages/email-response/customer-accept-request';
import BusPartnerAcceptRequest from '../pages/email-response/buspartner-accept-request';
import BusPartnerRejectRequest from '../pages/email-response/buspartner-reject-request';
import PageNotFound from '../pages/email-response/pagenotfound';
import CustomerConfirmedRequest from '../pages/email-response/customer-confirmed-request';
import BusPartnerConfirmation from '../pages/email-response/buspartner-confirmation';
import TripDetail from '../pages/trip/trip-detail';
import TripDetailWithConversation from '../pages/trip-with-conversation';
import PaymentSuccess from '../pages/email-response/payment-success';
import PaymentCancel from '../pages/email-response/payment-cancel';
import PaymentSuccessCheckout from '../pages/email-response/payment-success-checkout';

const LoadPublicRouteComponent = ({ component }) => (
  <LayoutComponent>
    <PublicRoute>{component}</PublicRoute>
  </LayoutComponent>
);
// const LoadProtectedRouteComponent = ({ component }) => <LayoutComponent><ProtectedRoute>{component}</ProtectedRoute></LayoutComponent>;

const RouterContainer = () => (
	<BrowserRouter>
		<Suspense fallback={null}>
			<AuthProvider>
				<Routes>
					<Route
						path="terms-conditions"
						element={
							<LoadPublicRouteComponent component={<TermsConditionPage />} />
						}
					/>
					<Route
						path="privacy-policy"
						element={
							<LoadPublicRouteComponent component={<PrivacyPolicyPage />} />
						}
					/>
					<Route
						path=":busPartnerCode/terms-conditions"
						element={
							<LoadPublicRouteComponent component={<TermsConditionPage />} />
						}
					/>
					<Route
						path=":busPartnerCode/privacy-policy"
						element={
							<LoadPublicRouteComponent component={<PrivacyPolicyPage />} />
						}
					/>
					<Route
						path=":busPartnerCode/"
						element={
							<LoadPublicRouteComponent component={<PriceCalculationPage />} />
						}
					/>
					<Route
						path=":busPartnerCode/offer/:tripCode"
						element={<LoadPublicRouteComponent component={<TripDetail />} />}
					/>
					<Route
						path=":busPartnerCode/offer/:tripCode/conversation"
						element={
							<LoadPublicRouteComponent
								component={<TripDetailWithConversation />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/payment-success"
						element={
							<LoadPublicRouteComponent component={<PaymentSuccess />} />
						}
					/>
					<Route
						path=":busPartnerCode/payment-cancel"
						element={<LoadPublicRouteComponent component={<PaymentCancel />} />}
					/>
					<Route
						path=":busPartnerCode/payment-success-checkout"
						element={
							<LoadPublicRouteComponent
								component={<PaymentSuccessCheckout />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/request-received"
						element={
							<LoadPublicRouteComponent component={<RequestSuccess />} />
						}
					/>
					<Route
						path=":busPartnerCode/buspartner-confirmation/:requestId"
						element={
							<LoadPublicRouteComponent
								component={<BusPartnerConfirmation />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/accept-request/:requestId"
						element={
							<LoadPublicRouteComponent
								component={<BusPartnerAcceptRequest />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/reject-request/:requestId"
						element={
							<LoadPublicRouteComponent
								component={<BusPartnerRejectRequest />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/client-confirmation/:requestId"
						element={
							<LoadPublicRouteComponent component={<CustomerAcceptRequest />} />
						}
					/>
					<Route
						path=":busPartnerCode/client-confirmed/:requestId"
						element={
							<LoadPublicRouteComponent
								component={<CustomerConfirmedRequest />}
							/>
						}
					/>
					<Route
						path=":busPartnerCode/pagenotfound"
						element={<LoadPublicRouteComponent component={<PageNotFound />} />}
					/>
					<Route />
					<Route
						path="pagenotfound"
						element={<LoadPublicRouteComponent component={<PageNotFound />} />}
					/>
					<Route
						path="*"
						element={<LoadPublicRouteComponent component={<PageNotFound />} />}
					/>
				</Routes>
			</AuthProvider>
		</Suspense>
	</BrowserRouter>
);

export default RouterContainer;
